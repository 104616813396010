@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/scss/custom.scss';

// //Import Light mode
@import './assets/scss/style.scss';


.inputError{
  color: #ff2323;
  font-family: "Nunito", sans-serif;
  font-size: 10px;
}

.athMovilBtn{
  width: 120px !important;
  padding: 0 !important;
}

